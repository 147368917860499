// eslint-disable-next-line 
import React, { useState } from "react";
import Eraser from '../Assets/Images/Eraser.svg';

//Styles
import "./RemoveButton.css";

function RemoveButton(props) {
  
  const onPressButton = () => {
    let newState = !props.isEditing;
    props.setEditState(newState);
  };

  return (
    <div onClick={() => onPressButton()} className={[props.className, "RemoveButton", props.isEditing ? "RemoveButton--active" : ""].join(' ')}>
      <img className="RemoveButton__image" src={Eraser} alt="Eraser Icon"/>
    </div>
  );
}

export default RemoveButton;