import React from "react";
import Unity, { UnityContent } from "react-unity-webgl";
import { Helmet } from "react-helmet";

//Components
// eslint-disable-next-line 
import ColorPicker from "./Components/ColorPicker";
import ModuleSection from "./Components/ModuleSection";
import ListSection from "./Components/ListSection";
import RemoveButton from "./Components/RemoveButton";
import Logo from "./Assets/Images/Logo.jpg";
import Spinner from "./Assets/Images/spinner-white-rounded.svg";

//Styles
import "./App.css";

export class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameReady: false,
      isEditing: false,
      isLoading: true,
    };

    this.unityContent = new UnityContent(
      "MyGame/Build/Build/Build.json",
      "MyGame/Build/Build/UnityLoader.js"
    );

    this.unityContent.on("GameReady", () => {
      this.setState({
        gameReady: true,
        isEditing: false,
      });
    });

    this.unityContent.on("progress", (progression) => {
      this.setState({
        progression: progression,
      });
    });

    this.unityContent.on("loaded", () => {
      this.setState({
        isLoading: false,
      });
    });

    this.setEditState = this.setEditState.bind(this);
  }

  setEditState(editState) {
    this.setState({ isEditing: editState });
    this.unityContent.send("KorgSystem", "ChangeMode", editState ? 1 : 0);
  }

  componentDidMount() {
    window.addEventListener("mousedown", (event) => {
      if (this.state.gameReady)
        if (event.target.id === "#canvas") {
          // Clicked on canvas
          this.unityContent.send("GameControl", "FocusCanvas", "1");
        } else {
          // Clicked outside of canvas
          this.unityContent.send("GameControl", "FocusCanvas", "0");
        }
    });
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          {/* Global site tag (gtag.js) - Google Analytics  */}
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-43645165-3"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-43645165-3');
            `}
          </script>
        </Helmet>

        <div className="App__header">
          <h1 className="App__header-title">
            Korg Planner
            <img src={Logo} alt="Logo" />
          </h1>
          <p className="App__header-link">
            Optimized for{" "}
            <a target="__blank" href="https://www.google.com/chrome">
              Google Chrome
            </a>
          </p>
        </div>

        <div className={"App__wrapper"}>
          <div className={this.state.isLoading ? "Loader" : "hidden"}>
            <img src={Spinner} alt="Loading" />
          </div>
          <Unity unityContent={this.unityContent} />
          <RemoveButton
            unityContent={this.unityContent}
            setEditState={this.setEditState}
            isEditing={this.state.isEditing}
            className={"App__wrapper-button"}
          >
            click
          </RemoveButton>
        </div>

        <ModuleSection
          unityContent={this.unityContent}
          setEditState={this.setEditState}
        />

        <ListSection unityContent={this.unityContent} />
      </div>
    );
  }
}

export default App;
