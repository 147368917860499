import React from "react";

//Styles
import "./ListSection.css";

export class ListSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [
        {
          articleId: 'U12-35F',
          name: 'Korg Building System Middle Section',
          amount: 1
        },
        {
          articleId: 'U12-32NC-1',
          name: 'Korg Building System Leg - Attachable',
          amount: 2
        }
      ]
    }

    this.props.unityContent.on("UpdateListOfComponents", data => {
      this.setState({
        list: JSON.parse(data)
      });
    });

  }

  render() {
    return (
      <div className="ListSection">

        <h2>Product specification<button onClick={() => window.print()} className="PrintBtn">Print to PDF</button></h2>

        <table className="Table">
          <thead>
            <tr>
              <th>Article ID</th>
              <th>Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {this.state.list.map(item => (
              <tr>
                <td>{item.articleId}</td>
                <td>{item.name}</td>
                <td>{item.amount} pcs</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    );
  }
}

export default ListSection;