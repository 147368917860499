import React from "react";

//Styles
import "./ModuleSection.css";

// Assets
import edgeImage from "../Assets/Images/Edge.png";
import concaveImage from "../Assets/Images/Concave.png";
import convexImage from "../Assets/Images/Convex.png";
import midLongImage from "../Assets/Images/Mid Long.png";
import midShortImage from "../Assets/Images/Mid Short.png";
import midExtraLongImage from "../Assets/Images/Middle Extra Long.png";

import ColorPicker from "./ColorPicker";

export class ModuleSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedModule: 0,
      modules: [
        {
          name: "End Section",
          image: edgeImage,
          value: 5
        },
        {
            name: "Concave Section",
            image: concaveImage,
            value: 0   
        },
        {
            name: "Convex Section",
            image: convexImage,
            value: 1   
        },
        {
          name: "Middle Section Short",
          image: midShortImage,
          value: 3
        },
        {
          name: "Middle Section",
          image: midLongImage,
          value: 2
        },

        {
          name: "Middle Section Long",
          image: midExtraLongImage,
          value: 4
        }
      ]
    };

    this.handleSelectedModule = this.handleSelectedModule.bind(this);

  }

  handleSelectedModule(value) {
    this.setState({selectedModule: value});
    this.props.setEditState(false);
    this.props.unityContent.send(
      "KorgSystem", 
      "ChangeSelectedItemToAttach", 
      value
    );
  }

  render() {
    return (
      <div className="ModuleSection">

        <div className="ModuleContainer">

          <h2>Modules</h2>

          <div className="SelectOptions">
            {this.state.modules.map(module => (
              <div className="Container">
                <img
                  key={module.value}
                  className={this.state.selectedModule === module.value? 'selected' : ''}
                  src={module.image}
                  alt={module.name}
                  onClick={() => this.handleSelectedModule(module.value)}
                />
                <p>{ module.name }</p>
              </div>
            ))}
          </div>

        </div>

        <div className="ColorPickerContainer">
          <h2>Colors</h2>
          <ColorPicker unityContent={this.props.unityContent} />
        </div>

      </div>
    );
  }
}

export default ModuleSection;