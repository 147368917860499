import React from "react";

//Styles
import "./ColorPicker.css";

const ralClassicMap = {
  "RAL1000": "#BEBD7F",
  "RAL1001": "#C2B078",
  "RAL1002": "#C6A664",
  "RAL1003": "#E5BE01",
  "RAL1004": "#CDA434",
  "RAL1005": "#A98307",
  "RAL1006": "#E4A010",
  "RAL1007": "#DC9D00",
  "RAL1011": "#8A6642",
  "RAL1012": "#C7B446",
  "RAL1013": "#EAE6CA",
  "RAL1014": "#E1CC4F",
  "RAL1015": "#E6D690",
  "RAL1016": "#EDFF21",
  "RAL1017": "#F5D033",
  "RAL1018": "#F8F32B",
  "RAL1019": "#9E9764",
  "RAL1020": "#999950",
  "RAL1021": "#F3DA0B",
  "RAL1023": "#FAD201",
  "RAL1024": "#AEA04B",
  "RAL1026": "#FFFF00",
  "RAL1027": "#9D9101",
  "RAL1028": "#F4A900",
  "RAL1032": "#D6AE01",
  "RAL1033": "#F3A505",
  "RAL1034": "#EFA94A",
  "RAL1035": "#6A5D4D",
  "RAL1036": "#705335",
  "RAL1037": "#F39F18",
  "RAL2000": "#ED760E",
  "RAL2001": "#C93C20",
  "RAL2002": "#CB2821",
  "RAL2003": "#FF7514",
  "RAL2004": "#F44611",
  "RAL2005": "#FF2301",
  "RAL2007": "#FFA420",
  "RAL2008": "#F75E25",
  "RAL2009": "#F54021",
  "RAL2010": "#D84B20",
  "RAL2011": "#EC7C26",
  "RAL2012": "#E55137",
  "RAL2013": "#C35831",
  "RAL3000": "#AF2B1E",
  "RAL3001": "#A52019",
  "RAL3002": "#A2231D",
  "RAL3003": "#9B111E",
  "RAL3004": "#75151E",
  "RAL3005": "#5E2129",
  "RAL3007": "#412227",
  "RAL3009": "#642424",
  "RAL3011": "#781F19",
  "RAL3012": "#C1876B",
  "RAL3013": "#A12312",
  "RAL3014": "#D36E70",
  "RAL3015": "#EA899A",
  "RAL3016": "#B32821",
  "RAL3017": "#E63244",
  "RAL3018": "#D53032",
  "RAL3020": "#CC0605",
  "RAL3022": "#D95030",
  "RAL3024": "#F80000",
  "RAL3026": "#FE0000",
  "RAL3027": "#C51D34",
  "RAL3028": "#CB3234",
  "RAL3031": "#B32428",
  "RAL3032": "#721422",
  "RAL3033": "#B44C43",
  "RAL4001": "#6D3F5B",
  "RAL4002": "#922B3E",
  "RAL4003": "#DE4C8A",
  "RAL4004": "#641C34",
  "RAL4005": "#6C4675",
  "RAL4006": "#A03472",
  "RAL4007": "#4A192C",
  "RAL4008": "#924E7D",
  "RAL4009": "#A18594",
  "RAL4010": "#CF3476",
  "RAL4011": "#8673A1",
  "RAL4012": "#6C6874",
  "RAL5000": "#354D73",
  "RAL5001": "#1F3438",
  "RAL5002": "#20214F",
  "RAL5003": "#1D1E33",
  "RAL5004": "#18171C",
  "RAL5005": "#1E2460",
  "RAL5007": "#3E5F8A",
  "RAL5008": "#26252D",
  "RAL5009": "#025669",
  "RAL5010": "#0E294B",
  "RAL5011": "#231A24",
  "RAL5012": "#3B83BD",
  "RAL5013": "#1E213D",
  "RAL5014": "#606E8C",
  "RAL5015": "#2271B3",
  "RAL5017": "#063971",
  "RAL5018": "#3F888F",
  "RAL5019": "#1B5583",
  "RAL5020": "#1D334A",
  "RAL5021": "#256D7B",
  "RAL5022": "#252850",
  "RAL5023": "#49678D",
  "RAL5024": "#5D9B9B",
  "RAL5025": "#2A6478",
  "RAL5026": "#102C54",
  "RAL6000": "#316650",
  "RAL6001": "#287233",
  "RAL6002": "#2D572C",
  "RAL6003": "#424632",
  "RAL6004": "#1F3A3D",
  "RAL6005": "#2F4538",
  "RAL6006": "#3E3B32",
  "RAL6007": "#343B29",
  "RAL6008": "#39352A",
  "RAL6009": "#31372B",
  "RAL6010": "#35682D",
  "RAL6011": "#587246",
  "RAL6012": "#343E40",
  "RAL6013": "#6C7156",
  "RAL6014": "#47402E",
  "RAL6015": "#3B3C36",
  "RAL6016": "#1E5945",
  "RAL6017": "#4C9141",
  "RAL6018": "#57A639",
  "RAL6019": "#BDECB6",
  "RAL6020": "#2E3A23",
  "RAL6021": "#89AC76",
  "RAL6022": "#25221B",
  "RAL6024": "#308446",
  "RAL6025": "#3D642D",
  "RAL6026": "#015D52",
  "RAL6027": "#84C3BE",
  "RAL6028": "#2C5545",
  "RAL6029": "#20603D",
  "RAL6032": "#317F43",
  "RAL6033": "#497E76",
  "RAL6034": "#7FB5B5",
  "RAL6035": "#1C542D",
  "RAL6036": "#193737",
  "RAL6037": "#008F39",
  "RAL6038": "#00BB2D",
  "RAL7000": "#78858B",
  "RAL7001": "#8A9597",
  "RAL7002": "#7E7B52",
  "RAL7003": "#6C7059",
  "RAL7004": "#969992",
  "RAL7005": "#646B63",
  "RAL7006": "#6D6552",
  "RAL7008": "#6A5F31",
  "RAL7009": "#4D5645",
  "RAL7010": "#4C514A",
  "RAL7011": "#434B4D",
  "RAL7012": "#4E5754",
  "RAL7013": "#464531",
  "RAL7015": "#434750",
  "RAL7016": "#293133",
  "RAL7021": "#23282B",
  "RAL7022": "#332F2C",
  "RAL7023": "#686C5E",
  "RAL7024": "#474A51",
  "RAL7026": "#2F353B",
  "RAL7030": "#8B8C7A",
  "RAL7031": "#474B4E",
  "RAL7032": "#B8B799",
  "RAL7033": "#7D8471",
  "RAL7034": "#8F8B66",
  "RAL7035": "#D7D7D7",
  "RAL7036": "#7F7679",
  "RAL7037": "#7D7F7D",
  "RAL7038": "#B5B8B1",
  "RAL7039": "#6C6960",
  "RAL7040": "#9DA1AA",
  "RAL7042": "#8D948D",
  "RAL7043": "#4E5452",
  "RAL7044": "#CAC4B0",
  "RAL7045": "#909090",
  "RAL7046": "#82898F",
  "RAL7047": "#D0D0D0",
  "RAL7048": "#898176",
  "RAL8000": "#826C34",
  "RAL8001": "#955F20",
  "RAL8002": "#6C3B2A",
  "RAL8003": "#734222",
  "RAL8004": "#8E402A",
  "RAL8007": "#59351F",
  "RAL8008": "#6F4F28",
  "RAL8011": "#5B3A29",
  "RAL8012": "#592321",
  "RAL8014": "#382C1E",
  "RAL8015": "#633A34",
  "RAL8016": "#4C2F27",
  "RAL8017": "#45322E",
  "RAL8019": "#403A3A",
  "RAL8022": "#212121",
  "RAL8023": "#A65E2E",
  "RAL8024": "#79553D",
  "RAL8025": "#755C48",
  "RAL8028": "#4E3B31",
  "RAL8029": "#763C28",
  "RAL9001": "#FDF4E3",
  "RAL9002": "#E7EBDA",
  "RAL9003": "#F4F4F4",
  "RAL9004": "#282828",
  "RAL9005": "#0A0A0A",
  "RAL9006": "#A5A5A5",
  "RAL9007": "#8F8F8F",
  "RAL9010": "#FFFFFF",
  "RAL9011": "#1C1C1C",
  "RAL9016": "#F6F6F6",
  "RAL9017": "#1E1E1E",
  "RAL9018": "#D7D7D7",
  "RAL9022": "#9C9C9C",
  "RAL9023": "#828282",
  "RAL2200": "#323438",
}

export class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidRal: false,
      ralInput: '' // #F6F6F6
    };

    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleSetColor = this.handleSetColor.bind(this);
    this.updateColorState = this.updateColorState.bind(this);

  }

  updateColorState(value){
    this.setState({ralInput: value }, () => {
      
      var ralLookup = ralClassicMap["RAL" + this.state.ralInput];
      if(ralLookup) {
        this.setState({invalidRal: false});
        this.props.unityContent.send(
          "KorgSystem",
          "ChangeColor",
          ralLookup
        );
      }
      else {
        this.setState({invalidRal: true});
      }
    });
  }

  handleColorChange(event) {
    this.updateColorState(event.target.value);
  }

  handleSetColor(value) {
    this.updateColorState(value);
  }

  render() {
    return (
      <div className="ColorPicker">

        <div className="Wrapper">

          <div className="presets">

            <div className="colorBlock" onClick={() => this.handleSetColor("2002")}>
              <div className="block first-pick"></div>
              <div className="label">2002</div>
            </div>

            <div className="colorBlock" onClick={() => this.handleSetColor("5008")}>
              <div className="block second-pick"></div>
              <div className="label">5008</div>
            </div>

            <div className="colorBlock" onClick={() => this.handleSetColor("7001")}>
              <div className="block third-pick"></div>
              <div className="label">7001</div>
            </div>

            <div className="colorBlock" onClick={() => this.handleSetColor("2200")}>
              <div className="block fourth-pick"></div>
              <div className="label">2200</div>
              <div className="label">Sablé</div>
            </div>

          </div>
          
          <div className="customInput">
            <label>Custom Color</label>
            <input type="text" placeholder="RAL CODE" defaultValue={this.state.ralInput} onChange={this.handleColorChange} />
          </div>


          <small className={ this.state.invalidRal ? 'ralError' : 'hidden' }>
            Please use a RAL-Classic code
          </small>

        </div>

      </div>

    );
  }
}

export default ColorPicker;